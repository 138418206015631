export default {
  tableData1: [{
    id: "114.246.237.216",
    action: [
      "绿创中心11场景01: 1次",
      "点击绿创中心一栋场景02 20次",
      "点击绿创中心一栋场景02 30次",
      "绿创中心一栋场景02 40次",
    ],
    location: "北京市",
    browser: "Win32\nChrome\n1280*720",
    time: "2024/03/13 17:09:19"
  },
    {
      id: "114.246.237.216",
      action: [
        "绿创中心11场景01: 1次",
        "点击绿创中心一栋场景02 20次",
        "点击绿创中心一栋场景02 30次",
        "绿创中心一栋场景02 40次",
      ],
      location: "北京市",
      browser: "Win32\nChrome\n1280*720",
      time: "2024/03/13 17:09:19"
    },
    {
      id: "114.246.237.216",
      action: [
        "绿创中心11场景01: 1次",
        "点击绿创中心一栋场景02 20次",
        "点击绿创中心一栋场景02 30次",
        "绿创中心一栋场景02 40次",
      ],
      location: "北京市",
      browser: "Win32\nChrome\n1280*720",
      time: "2024/03/13 17:09:19"
    },
  ],

  tableData2: [{
    id: "14.26.237.216",
    action: [
      "绿创中心11场景01: 1次",
      "点击绿创中心一栋场景02 20次",
      "点击绿创中心一栋场景02 30次",
      "绿创中心一栋场景02 40次",
    ],
    location: "北京市",
    browser: "Win32\nChrome\n1280*720",
    time: "2024/03/13 17:09:19"
  },
    {
      id: "114.246.237.216",
      action: [
        "绿创中心11场景01: 1次",
        "点击绿创中心一栋场景02 20次",
        "点击绿创中心一栋场景02 30次",
        "绿创中心一栋场景02 40次",
      ],
      location: "北京市",
      browser: "Win32\nChrome\n1280*720",
      time: "2024/03/13 17:09:19"
    },
  ],

  tableData3: [{
    id: "114.246.237.216",
    action: [
      "绿创中心11场景01: 1次",
      "点击绿创中心一栋场景02 20次",
      "点击绿创中心一栋场景02 30次",
      "绿创中心一栋场景02 40次",
    ],
    location: "北京市",
    browser: "Win32\nChrome\n1280*720",
    time: "2024/03/13 17:09:19"
  },
    {
      id: "114.246.237.216",
      action: [
        "绿创中心11场景01: 1次",
        "点击绿创中心一栋场景02 20次",
        "点击绿创中心一栋场景02 30次",
        "绿创中心一栋场景02 40次",
      ],
      location: "北京市",
      browser: "Win32\nChrome\n1280*720",
      time: "2024/03/13 17:09:19"
    },
    {
      id: "114.246.237.216",
      action: [
        "绿创中心11场景01: 1次",
        "点击绿创中心一栋场景02 20次",
        "点击绿创中心一栋场景02 30次",
        "绿创中心一栋场景02 40次",
      ],
      location: "北京市",
      browser: "Win32\nChrome\n1280*720",
      time: "2024/03/13 17:09:19"
    },
  ],

};