<template>
  <div class="page">
    <div class="nav">
      <span style="padding-left: 20px">访问统计一览页面</span>
      <el-menu :default-active="activeName" mode="horizontal">
        <el-menu-item index="first">访问统计</el-menu-item>
        <el-menu-item>开发式后台</el-menu-item>
      </el-menu>
    </div>
    <div class="head">
      <el-row :gutter="6">
        <el-col :xs="8" :sm="6" :md="4">
          <div style="padding: 8px">
            <el-statistic style="text-align: center" value-style="display: flex;justify-content: center;"
              :value="value1" title="今日浏览量(PV)"></el-statistic>
          </div>
        </el-col>
        <el-col :xs="8" :sm="6" :md="4">
          <div style="padding: 8px">
            <el-statistic style="text-align: center" value-style="display: flex;justify-content: center;"
              :value="value2" title="今日IP"></el-statistic>
          </div>
        </el-col>
        <el-col :xs="8" :sm="6" :md="4">
          <div style="padding: 8px">
            <el-statistic style="text-align: center" value-style="display: flex;justify-content: center;" :precision="2"
              :value="value3" title="昨日浏览量"></el-statistic>
          </div>
        </el-col>
        <el-col :xs="8" :sm="6" :md="4">
          <div style="padding: 8px">
            <el-statistic style="text-align: center" value-style="display: flex;justify-content: center;"
              :value="value4" title="昨日IP"></el-statistic>
          </div>
        </el-col>
        <el-col :xs="8" :sm="6" :md="4">
          <div style="padding: 8px">
            <el-statistic style="text-align: center" value-style="display: flex;justify-content: center;"
              :value="value5" title="近7天PV"></el-statistic>
          </div>
        </el-col>
        <el-col :xs="8" :sm="6" :md="4">
          <div style="padding: 8px">
            <el-statistic style="text-align: center" value-style="display: flex;justify-content: center;"
              :value="value6" title="近7天IP"></el-statistic>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="content">
      <el-card class="box-card">
        <div style="display: flex;justify-content: space-between;margin-bottom: 8px">
          <span>流量趋势</span>
          <el-radio-group v-model="radio1" size="small">
            <el-radio-button label="7">最近7天</el-radio-button>
            <el-radio-button label="14">最近14天</el-radio-button>
            <el-radio-button label="30">最近30天</el-radio-button>
            <el-radio-button label="60">最近60天</el-radio-button>
          </el-radio-group>
        </div>
        <div class="Echarts">
          <div id="line" style="width: 100%;height: 300px;"></div>
        </div>
      </el-card>


      <el-card class="box-card">
        <div style="display: flex;justify-content: space-between;margin-bottom: 8px">
          <span>访客来源</span>
          <el-radio-group v-model="radio2" size="small">
            <el-radio-button label="1">今天</el-radio-button>
            <el-radio-button label="2">最近14天</el-radio-button>
            <el-radio-button label="3">最近30天</el-radio-button>
            <el-radio-button label="4">最近60天</el-radio-button>
          </el-radio-group>
        </div>
        <div class="Echarts">
          <div id="map" style="width: 100%;height: 300px;"></div>
        </div>
      </el-card>


      <el-card class="box-card">
        <div style="display: flex;justify-content: space-between;margin-bottom: 8px">
          <span>数据占比</span>
          <el-radio-group v-model="radio3" size="small">
            <el-radio-button label="1">今天</el-radio-button>
            <el-radio-button label="14">最近14天</el-radio-button>
            <el-radio-button label="30">最近30天</el-radio-button>
            <el-radio-button label="60">最近60天</el-radio-button>
          </el-radio-group>
        </div>
        <div class="grid-container">
          <div class="grid-item" id="pie1" style="width: calc(100% - 40px);height: 300px;"></div>
          <div class="grid-item" id="pie2" style="width: calc(100% - 40px);height: 300px;"></div>
          <div class="grid-item" id="pie3" style="width: calc(100% - 40px);height: 300px;"></div>
          <div class="grid-item" id="pie4" style="width: calc(100% - 40px);height: 300px;"></div>
        </div>
      </el-card>


      <el-card class="box-card">
        <div style="display: flex;justify-content: space-between;margin-bottom: 8px">
          <div style="display: flex;flex-direction: row;align-items: center;justify-content: space-between;">
            <span>用户轨迹({{ (tableData || []).length }})</span>
            <div style="margin-left: 10px;"> <el-button @click="export1" type="primary" size="small">下载</el-button></div>
          </div>
          <el-radio-group v-model="radio4" size="small">
            <el-radio-button label="1">今天</el-radio-button>
            <el-radio-button label="2">最近2天</el-radio-button>
            <el-radio-button label="3">最近3天</el-radio-button>
          </el-radio-group>
        </div>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="ip" label="访问IP" width="180">
          </el-table-column>
          <el-table-column prop="content" label="操作内容" width="250">
            <template v-slot="scope">
              <div>
                <li v-for="item in scope.row.content" :key="item">
                  {{scope.row.name}} {{ item.name }} {{ item.num }}次
                </li>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="city" label="访问地址">
          </el-table-column>
          <el-table-column prop="port" label="访问设备">
          </el-table-column>
          <el-table-column prop="mobile" label="手机号">
          </el-table-column>
          <el-table-column prop="username" label="名称">
          </el-table-column>
        </el-table>
      </el-card>



    </div>
  </div>
</template>



<script setup>
import { ref, watch } from 'vue'
import * as echarts from "echarts";
import { onMounted } from "vue";
// import data from './json/tubiao1';
// import cityData from './json/tubiao2';
// import peiData from './json/tubiao3';
import tableDatas from './json/tubiao4';
import city from './json/city';
import axios from "axios";

import china from "echarts/map/json/china.json";
echarts.registerMap("china", china);

const url = ref('http://47.120.41.78')

document.title ="访问统计"

// 标题的数据，请求后端获取
const value1 = ref(0)
const value2 = ref(0)
const value3 = ref(0)
const value4 = ref(0)
const value5 = ref(0)
const value6 = ref(0)

const activeName = ref('first')
const radio1 = ref('1')
const radio2 = ref('1')
const radio3 = ref('1')
const radio4 = ref('1')

const tableData = ref([])

//声明周期函数，自动执行初始化
onMounted(() => {
  initLine('7');
  initMap('1');
  initPie('1');
  initTable('1');
  tableData.value = tableDatas.tableData1
  axios.get(url.value + '/index.php/api/pano/initData')
    .then(function (res) {
      // 请求失败处理
      console.log(res);
      value1.value = res.data.data.view
      value2.value = res.data.data.ip_view
      value3.value = res.data.data.view1
      value4.value = res.data.data.ip_view1
      value5.value = res.data.data.view7
      value6.value = res.data.data.ip_view7
    })
    .catch(function (error) {
      // 请求失败处理
      console.log(error);
    });
});

//初始化曲线图
function initLine(type) {
  // const chartData = data[`dateCountList${type}`];
  // console.log(chartData);
  axios.get(url.value + '/index.php/api/pano/init1?day=' + type)
    .then(function (res) {
      console.log('res', res);
      // 基于准备好的dom，初始化echarts实例
      let Chart = echarts.init(document.getElementById("line"));
      // 绘制图表
      let options = {
        tooltip: {},
        xAxis: {
          type: 'category',
          boundaryGap: false,
          // data: chartData.map(item => item.time)
          data: res.data.data.date
        },
        yAxis: {
          type: 'value'
        },
        legend: {
          data: ['浏览量', '访问IP数']
        },
        series: [
          {
            name: "浏览量",
            type: "line",
            stack: 'Total',
            // data: chartData.map(item => item.reading),
            data: res.data.data.view,
            color: '#4ca7e7'
          },
          {
            name: "访问IP数",
            type: "line",
            stack: 'Total',
            //data: chartData.map(item => item.ipNum),
            data: res.data.data.ip_view,
            color: '#e3425e'
          },
        ],
      };
      // 渲染图表
      Chart.setOption(options);
    })
    .catch(function (error) {
      // 请求失败处理
      console.log(error);
    });

}
const export1 = () => {
  axios.get(url.value + '/index.php/api/pano/export?day='+radio4.value)
    .then(function (res) {
      console.log('res',res);
      window.location.href = res.data.data.path
    })
}
//初始化地图
function initMap(type) {
  // const chartData = cityData[`cityList${type}`]
  axios.get(url.value + '/index.php/api/pano/init2?day=' + type)
    .then(function (res) {
      console.log(res);
       const myChart = echarts.init(document.getElementById("map"));
  echarts.registerMap('china', china);
  let option = {
    geo: {
      map: 'china',
      label: {
        emphasis: {
          show: true
        }
      },
      //是否可以点击鼠标、滚轮缩放
      roam: false,
    },
    series: [
      {
        type: 'effectScatter',
        coordinateSystem: 'geo',
        rippleEffect: {
          brushType: 'stroke'
        },
        label: {
          normal: {
            show: true,
            position: 'right',
            formatter: '',
          }
        },
        symbolSize: 8,
        itemStyle: {
          normal: {
            color: '#60b8ec'
          }
        },
        data: res.data.data.map(item => ({
          name: item,
          value: city[item]
        }))
      },
    ],
  };
  myChart.setOption(option);
    })
    .catch(function (error) {
      // 请求失败处理
      console.log(error);
    });
 
}

//初始化饼图
function initPie(type) {
  const myChart1 = echarts.init(document.getElementById("pie1"));
  const myChart2 = echarts.init(document.getElementById("pie2"));
  const myChart3 = echarts.init(document.getElementById("pie3"));
  const myChart4 = echarts.init(document.getElementById("pie4"));
  axios.get(url.value + '/index.php/api/pano/init3?day='+type)
    .then(function (res) {
      console.log(res);
      const option1 = {
        tooltip: {
          trigger: 'item'
        },
        title: {
          text: ' 新老用户比例',
          y: 'bottom',
          x: 'center'
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '50%',
            data: res.data.data.user,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };

      const option2 = {
        tooltip: {
          trigger: 'item'
        },
        title: {
          text: ' 访问设备比例',
          y: 'bottom',
          x: 'center'
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '50%',
            data: res.data.data.prot,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };

      const option3 = {
        tooltip: {
          trigger: 'item'
        },
        title: {
          text: ' 作品点击比例',
          y: 'bottom',
          x: 'center'
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '50%',
            data: res.data.data.pano,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };

      const option4 = {
        tooltip: {
          trigger: 'item'
        },
        title: {
          text: ' 场景点击比例',
          y: 'bottom',
          x: 'center'
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '50%',
            data: res.data.data.view,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      myChart1.setOption(option1);
      myChart2.setOption(option2);
      myChart3.setOption(option3);
      myChart4.setOption(option4);
    })
    .catch(function (error) {
      // 请求失败处理
      console.log(error);
    });
}
//初始表格
function initTable(type)
{
  tableData.value = [];
  axios.get(url.value + '/index.php/api/pano/init4?day='+type)
    .then(function (res) {
      console.log('res',res);
      tableData.value = res.data.data
    })
    .catch(function (error) {
      // 请求失败处理
      console.log(error);
    });
}

watch(
  radio1, (val) => {
    initLine(val)
  },
)

watch(
  radio2, (val) => {
    initMap(val)
  },
)

watch(
  radio3, (val) => {
    initPie(val)
  },
)

watch(
  radio4, (val) => {
    initTable(val)
  },
)

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page {
  background-color: #efefee;
  display: flex;
  flex-direction: column;
}

.head {
  background: #fff;
  margin-bottom: 15px;
  padding: 24px;
}

.box-card {
  margin-bottom: 15px;
  width: 100%;
}

.content {
  display: flex;
  background-color: #efefee;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    font-weight: bold;
  }
}

/* 基础的网格样式 - 默认4行1列 */
.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  /* 创建一个单列布局 */
  gap: 10px;
  /* 网格项之间的间隙 */
}

.grid-item {
  text-align: center;
  /* 文本居中对齐 */
  padding: 20px;
  /* 内填充 */
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  font-weight: bold;
  background: #fff;
}


/* 使用媒体查询在屏幕宽度大于600px时改变网格布局为2行2列 */
@media (min-width: 600px) {
  .grid-container {
    grid-template-columns: 1fr 1fr;
    /* 创建两列布局 */
  }

  .nav {
    padding: 0 150px;
  }

  .head {
    margin: 0 auto 15px;
    width: 1040px;
  }

  .content {
    margin: 0 auto;
    width: 1088px;
  }
}
</style>
